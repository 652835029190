<template>
  <v-footer dark app bottom padless absolute class="info">
    <v-card width="100%" flat tile class="info white--text text-center">
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4 white--text"
          icon
          :href="icon.link"
        >
          <v-icon color="white" size="24px">{{ icon.icon }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        <p v-html="$t('footer.FindUS')"></p>
        <p v-html="$t('footer.ContactUs')"></p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        <p style="color: white">
          <strong
            >{{ $t("footer.Allrights") }} &copy;
            {{ new Date().getFullYear() }} - {{ new Date().getFullYear() + 1 }}
            {{ $t("footer.allrig") }}</strong
          >
        </p>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "CoreFooter",
  data: () => ({
    icons: [
      {
        icon: "mdi-facebook",
        link: "https://www.facebook.com/%D8%B4%D8%B1%D9%83%D8%A9-%D9%81%D8%A7%D9%8A%D8%A8%D8%B1-%D8%B9%D8%B1%D8%A7%D9%82-Fiber-Iraq-104391925314717/?mibextid=LQQJ4d",
      },
      { icon: "mdi-twitter", link: "#" },
      {
        icon: "mdi-linkedin",
        link: "#",
      },
      {
        icon: "mdi-youtube",
        link: "#",
      },
      {
        icon: "mdi-instagram",
        link: "#",
      },
    ],
  }),
};
</script>

<style scoped></style>
